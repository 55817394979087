import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon";
import "@yzfe/svgicon/lib/svgicon.css";
import install from "./install";
import i18n from "./i18n";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCLropmJcQmYwMPKgUjMQ01ihQMfdqzKLs",
  authDomain: "octabis-v2.firebaseapp.com",
  databaseURL: "https://octabis-v2-default-rtdb.firebaseio.com",
  projectId: "octabis-v2",
  storageBucket: "octabis-v2.appspot.com",
  messagingSenderId: "1057124879332",
  appId: "1:1057124879332:web:00cd456422ff15ae42d5f1",
  measurementId: "G-MRB95RBH7E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

createApp(App)
  .use(i18n)
  .use(install)
  .use(VueSvgIconPlugin, { tagName: "icon" })
  .use(store)
  .use(router)
  .mount("#app");
