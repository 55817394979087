import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
import HomeView from "../views/HomeView.vue";
import index from "../views/index";

const routes = [
  {
    path: "/about",
    name: "home",
    component: HomeView,
  },
  {
    path: "/",
    name: "Index",
    component: index,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: pathLoader("home", "index"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/help",
        name: "Help",
        component: pathLoader("help", "index"),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "/general-terms",
        name: "Terms",
        component: pathLoader("terms", "index"),
        meta: {
          requiresAuth: false,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
