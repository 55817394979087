export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "learnCodeMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprendre le code"])},
  "getLicenseMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir son permis"])},
  "loginMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "downloadButtonMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "formationFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation"])},
  "assistedDrivingFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conduite assistée"])},
  "learnerSpaceFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace apprenant"])},
  "drivingSchoolFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-écoles"])},
  "helpFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide"])},
  "ourPartnersFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos partenaires"])},
  "actualityFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités"])},
  "careerFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "contactFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "legalNoticeFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
  "termsAndConditionsFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales"])},
  "privacyPoliciesFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politiques de confidentialité"])},
  "allRightReservedFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés."])},
  "getYourDrivingLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez votre permis \nde conduire avec Brillo"])},
  "theFirstApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La première application qui vous prépare pour l’examen du permis de conduire béninois partout où vous êtes, quand vous voulez, comme vous le voulez"])},
  "availableAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible sur :"])},
  "accessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessible"])},
  "accessibleCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des cours et des exercices acessibles en ligne 24h/24."])},
  "practice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratique"])},
  "codeWhenYouWant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code quand vous voulez selon votre disponibilité."])},
  "economic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Économique"])},
  "formationNotExpensive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une formation moins chère et sans engagement."])},
  "roadCodeAnywhere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de la route avec vous <span style='color: #FE5641;'>partout</span>"])},
  "accessCompleteFormation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à une formation complète au code de la route quelque soit où vous vous trouvez, 24h/24. Aucune contrainte d’horaires ou d’agenda. Avec E-moniteur c’est où vous voulez, quand vous le voulez."])},
  "knowMoreButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
  "madeForSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conçue pour assurer votre réussite du <span style='color: #FE5641;'>premier coup</span>"])},
  "accessFormationOnPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à une formation complète au code de la route sur votre téléphone quelque soit où vous vous trouvez, 24h/24. Aucune contrainte d’horaires et de disponibilité. C’est où vous voulez, quand vous le voulez."])},
  "helpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre d'aide"])},
  "needToKnowMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin d’en savoir plus, découvrez tout ce que vous devez savoir ici"])},
  "frequentlyQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question fréquemment posés"])},
  "youAreCandidates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes candidat ?"])},
  "youAreSchool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes une auto-école ?"])},
  "ourOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre fonctionnement, nos offres, tout savoir sur la formation que nous offrons chez e-Moniteur."])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])},
  "questionAboutOffers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une question sur nos offres ou sur votre formation au permis de conduire ? Notre équipe vous accompagne !\nDu lundi au samedi, de 08h à 18h."])},
  "contactUsButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous <span style='font-weight: 700;'>229 41241344</span>"])}
}