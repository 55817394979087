<template>
  <div class="button-base">
    <button
      class="btn page-button-real py-2 px-8 rounded-full font-normal flex items-center justify-center cursor-pointer"
      :style="{ backgroundColor: background, color: fontcolor }"
      :class="{ 'border-dotted': borderDot, border: !borderDot }"
    >
      <icon
        v-if="showIcon"
        :data="nameIcon"
        original
        class="icon w-4 h-4 mr-2"
      />
      <h4 v-if="!isLoading" class="font-medium text-headerBlackColor">
        {{ label }}
      </h4>
      <h6 v-if="isLoading">
        <loading
          :active="isLoading"
          :can-cancel="true"
          :on-cancel="onCancel"
          :is-full-page="fullPage"
          background-color="transparent"
          color="#FFFFFF"
          :height="30"
        >
        </loading>
      </h6>
    </button>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "button-base",
  components: {
    Loading,
  },
  props: {
    label: {
      type: String,
      default: "Button",
    },
    nameIcon: {
      type: String,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    // TODO HERE IF HE WANT PREVIOUS COLOR
    background: {
      type: String,
      default: "#0CE788",
    },
    fontcolor: {
      type: String,
      default: "#000",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    borderDot: {
      type: Boolean,
      default: false,
    },
    fullPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: "#FFFFFF",
    };
  },
  methods: {
    onCancel() {
      console.log("User cancelled the loader.");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.btn {
  border: solid transparent 1px;
}
.vld-overlay {
  position: relative;
}
</style>
