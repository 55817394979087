<template>
  <div class="the-footer">
    <div class="begin">
      <div class="content">
        <div class="footer-container xl:px-44 md:px-16 px-4 py-12">
          <div
            class="footer-container-one border-t border-b border-headerBorderColor flex flex-wrap justify-between pb-16"
          >
            <div class="part-one md:w-1/4 mt-12">
              <div class="title font-semibold text-lg text-headerBlackColor">
                {{ $t("formationFooter") }}
              </div>
              <br />
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                {{ $t("learnCodeMenu") }}
              </div>
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                {{ $t("getLicenseMenu") }}
              </div>
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                {{ $t("assistedDrivingFooter") }}
              </div>
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                {{ $t("learnerSpaceFooter") }}
              </div>
            </div>
            <div class="part-one md:w-1/4 mt-12">
              <div class="title font-semibold text-lg text-headerBlackColor">
                E-moniteur
              </div>
              <br />
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
                @click="openHelp()"
              >
                {{ $t("helpFooter") }}
              </div>
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                {{ $t("ourPartnersFooter") }}
              </div>
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                {{ $t("actualityFooter") }}
              </div>
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                {{ $t("careerFooter") }}
              </div>
            </div>
            <div class="part-one md:w-1/4 mt-12">
              <div class="title font-semibold text-lg text-headerBlackColor">
                {{ $t("contactFooter") }}
              </div>
              <br />
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                Tel : (229) 41 24 13 44
              </div>
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                Whatsapp : (229) 41 24 13 44
              </div>
              <div
                class="description text-footerColor font-medium mb-3 cursor-pointer hover:text-buttonBackgroundPrimary"
              >
                service@e-moniteur.com
              </div>
            </div>
            <div class="part-one md:w-1/4 mt-12">
              <div class="store cursor-pointer mb-3">
                <icon
                  :data="icons.google_store"
                  original
                  width="168.75"
                  height="50"
                  @click="openPlaySore()"
                ></icon>
              </div>
              <div class="store cursor-pointer mt-3">
                <icon
                  :data="icons.apple_store"
                  original
                  width="168.75"
                  height="50"
                  @click="openAppSore()"
                ></icon>
              </div>
              <br />
              <div class="logos flex items-center">
                <div
                  class="logo-icon mr-4 cursor-pointer"
                  @click="openFacebook()"
                >
                  <icon
                    :data="icons.facebook"
                    original
                    width="32"
                    height="32"
                  ></icon>
                </div>
                <div class="logo-icon mr-4 cursor-pointer">
                  <icon
                    :data="icons.twitter"
                    original
                    width="32"
                    height="32"
                  ></icon>
                </div>
                <div class="logo-icon mr-4 cursor-pointer">
                  <icon
                    :data="icons.instagram"
                    original
                    width="32"
                    height="32"
                  ></icon>
                </div>
              </div>
            </div>
          </div>
          <div
            class="footer-container-two text-center text-sm font-medium py-6"
          >
            <div class="item-one">
              <span class="cursor-pointer">{{ $t("legalNoticeFooter") }}</span>
              <span class="px-2">{{ "   •   " }}</span>
              <span class="cursor-pointer" @click="openTerms">{{
                $t("termsAndConditionsFooter")
              }}</span>
              <!--              <span class="px-2">{{ "   •   " }}</span>
              <span class="cursor-pointer">{{ $t("privacyPoliciesFooter") }}</span>-->
            </div>
            <div class="item-two mt-3">
              {{
                "© " +
                new Date().getFullYear() +
                " E-moniteur. " +
                $t("allRightReservedFooter")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import google_store from "../assets/icons/ic_google-store.svg";
import apple_store from "../assets/icons/ic_apple-store.svg";
import facebook from "../assets/icons/ic_facebook.svg";
import instagram from "../assets/icons/ic_instagram.svg";
import twitter from "../assets/icons/ic_twitter.svg";
export default {
  name: "the-footer",
  data() {
    return {
      icons: { google_store, apple_store, facebook, instagram, twitter },
    };
  },
  methods: {
    openPlaySore() {
      window.open(
        "https://play.google.com/store/apps/details?id=co.kodora.studentsapp",
        "_blank"
      );
    },
    openAppSore() {
      window.open(
        "https://apps.apple.com/us/app/e-moniteur/id6449436396",
        "_blank"
      );
    },
    openFacebook() {
      window.open("https://www.facebook.com/emoniteur", "°blank");
    },
    openHelp() {
      this.$router.push({ path: "/help" });
    },
    openTerms() {
      this.$router.push({ path: "/general-terms" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
</style>
