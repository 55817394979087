<template>
  <div class="the-header">
    <div class="begin">
      <div class="content">
        <div
          class="header-container lg:flex items-center justify-between py-4 xl:px-44 md:px-16 border-b border-headerBorderColor hidden"
        >
          <div class="logo-icon cursor-pointer" @click="openHome()">
            <icon :data="icons.logo" original width="250" height="50"></icon>
          </div>
          <div
            class="menu-option flex items-center text-headerBlackColor font-medium text-[15px]"
          >
            <div class="menu-action mr-6 cursor-pointer hidden">
              {{ $t("learnCodeMenu") }}
            </div>
            <div class="menu-action mx-6 cursor-pointer hidden">
              {{ $t("getLicenseMenu") }}
            </div>
            <div class="menu-action mx-6 cursor-pointer" @click="openLogin()">
              {{ $t("loginMenu") }}
            </div>
            <div class="menu-action ml-6 cursor-pointer">
              <button-base :label="$t('downloadButtonMenu')"></button-base>
            </div>
          </div>
        </div>

        <!-- RESPONSIVE HEADER -->
        <div class="responsive-header lg:hidden">
          <div
            v-if="!isMenu"
            class="header-container flex items-center justify-between py-4 px-4 border-b border-headerBorderColor"
          >
            <div class="logo-icon cursor-pointer" @click="openHome()">
              <icon :data="icons.logo" original width="164" height="34"></icon>
            </div>
            <div class="burger">
              <div
                class="burger-menu flex items-center bg-[#F9F9FC] rounded-[10px] py-2 px-4"
                @click="isMenu = true"
              >
                <div class="burger-icon mr-4">
                  <icon
                    :data="icons.ic_open"
                    original
                    width="20"
                    height="20"
                  ></icon>
                </div>
                <div class="font-bold text-[14px]">Menu</div>
              </div>
            </div>
          </div>

          <div
            v-if="isMenu"
            class="header-container-menu z-50 bg-white w-full h-screen absolute"
          >
            <div class="menus p-8">
              <div
                class="burger-menu flex items-center justify-center bg-[#F9F9FC] rounded-[10px] py-2 px-4 w-[106px]"
                @click="isMenu = false"
              >
                <div class="burger-icon mr-4">
                  <icon
                    :data="icons.ic_close"
                    original
                    width="20"
                    height="20"
                  ></icon>
                </div>
                <div class="font-bold text-[14px]">Fermer</div>
              </div>
              <br />
              <div
                class="menu-action mx-6 cursor-pointer font-semibold text-[20px] text-[#000814] mt-6 hidden"
              >
                {{ $t("learnCodeMenu") }}
              </div>
              <br />
              <div
                class="menu-action mx-6 cursor-pointer font-semibold text-[20px] text-[#000814] mt-6 hidden"
              >
                {{ $t("getLicenseMenu") }}
              </div>
              <br />
              <div
                class="menu-action mx-6 cursor-pointer font-semibold text-[20px] text-[#000814] mt-6"
                @click="openLogin()"
              >
                {{ $t("loginMenu") }}
              </div>
              <br />
              <div
                class="menu-action mx-6 cursor-pointer text-center font-medium text-[14px] text-[#7E7E7E] mt-6"
              >
                Télécharger sur :
              </div>
              <br />
              <div
                class="store-download mx-6 flex flex-wrap items-center justify-center lg:justify-start mt-4"
              >
                <div class="play_store md:mr-3 mb-6 md:mb-0">
                  <button-base
                    label="Play Store"
                    :show-icon="true"
                    :name-icon="icons.play"
                    @click="openPlaySore()"
                  ></button-base>
                </div>
                <div class="apple_store md:ml-3 mb-6 md:mb-0">
                  <button-base
                    label="App Store"
                    :show-icon="true"
                    :name-icon="icons.apple"
                    @click="openPlaySore()"
                  ></button-base>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../assets/icons/ic_logo.svg";
import ButtonBase from "@/components/helper/add/button-base";
import ic_open from "@/assets/icons/ic_burger-menu.svg";
import ic_close from "@/assets/icons/ic_burger-close.svg";
import apple from "@/assets/icons/ic_apple.svg";
import play from "@/assets/icons/ic_google-play.svg";
export default {
  name: "the-header",
  components: { ButtonBase },
  data() {
    return {
      icons: { logo, ic_open, ic_close, apple, play },
      isMenu: false,
    };
  },
  methods: {
    openHome() {
      this.$router.push({ path: "/home" });
    },
    openLogin() {
      window.open("https://admin.e-moniteur.com/", "_blank");
    },
    openPlaySore() {
      window.open(
        "https://play.google.com/store/apps/details?id=co.kodora.studentsapp",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/sass/variables";
.menus {
  text-align: -moz-center;
  text-align: -webkit-center;
}
.play_store:deep(.button-base),
.apple_store:deep(.button-base) {
  .page-button-real {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    .icon {
      width: 19px;
      height: 19px;
    }
    h4 {
      font-size: 14px;
      font-weight: 600;
    }
  }
}
</style>
