<template>
  <div class="home">
    <div class="begin h-screen">
      <div class="content">
        <div class="flex items-center justify-center text-white h-screen">
          <div class="flex flex-col items-center text-center md:w-3/5">
            <div class="logo">
              <icon :data="icons.logo" original width="250" height="50"></icon>
            </div>
            <br />
            <div class="title font-black lg:text-7xl text-6xl mt-8">
              Votre plateforme arrive très bientôt
            </div>
            <br />
            <div class="description md:text-xl xl:mx-56 mx-12 mt-8">
              Inscrivez-vous maintenant et bénéfiez de nos offres spéciales pour
              les 200 premiers inscrits.
            </div>
            <br />
            <div class="buttons mt-8">
              <button
                class="btn text-black md:text-xl bg-white border border-green rounded-full py-4 px-12 font-medium"
                @click="openForm()"
              >
                Pré-inscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "../assets/icons/ic_logo.svg";
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      icons: { logo },
    };
  },
  methods: {
    openForm() {
      window.open("https://forms.gle/byqzDgvEWQ94yTfY6", "_blank");
    },
  },
};
</script>

<style scoped>
.svg-fill {
  fill: transparent;
}
.begin {
  background: #085e3f;
}
button {
  border-color: #0ce788;
}
</style>
