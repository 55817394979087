<template>
  <div class="home-page">
    <div class="begin">
      <div class="content">
        <div class="header">
          <the-header></the-header>
        </div>
        <div class="body">
          <router-view />
        </div>
        <div class="footer">
          <the-footer></the-footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/the-header";
import TheFooter from "@/components/the-footer";
export default {
  name: "home-page",
  components: { TheFooter, TheHeader },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/sass/variables";
</style>
